<template>
  <v-layout column md12>
    <div class="mb-3">
      <page-title
        title="Projects General Report"
        subtitle="Digital Platform & Service"
        :useBackBtn="true"
      ></page-title>
    </div>

    <v-card elevation="1" class="col-md-12 mt-2">
      <div class="row">
        <div class="col-md-4 col-sm-12">
          <v-card flat class="col-md-12">
            <div class="col-md-12">
              <ProjectGauge :heightData="300" :seriesData="[57]" />
            </div>
            <h3 class="text-dark pa-3">
              Timeline : <br />
              <span style="font-size: 22px"
                >January 4,2022 - December 4, 2023</span
              >
            </h3>
            <h3 class="text-dark pa-3">
              Actor Organization :
              <span style="font-size: 20px">TCRA </span>
            </h3>
          </v-card>
        </div>
        <div class="col-md-8 col-sm-12">
          <v-card elevation="1">
            <v-chart class="chart col-md-11" :option="option" />
          </v-card>
        </div>
      </div>
    </v-card>

    <v-card elevation="1" class="col-md-12 mt-2">
      <div class="row">
        <div class="col-md-4 col-sm-12">
          <p>KEYS:</p>
          <v-icon color="primary">mdi-flag</v-icon> Pending
          <v-icon color="orange">mdi-flag</v-icon> On Progress
          <v-icon color="pink">mdi-flag</v-icon> On Hold
          <v-icon color="success">mdi-flag</v-icon> Completed
          <v-icon color="secondary">mdi-flag</v-icon> Delayed
        </div>
      </div>
    </v-card>

    <v-card elevation="1" class="col-md-12">
      <table>
        <template>
          <thead>
            <tr>
              <th colspan="35">  Digital Platform & Service</th>
            </tr>
            <tr>
              <th rowspan="2"><strong>Activity Na</strong></th>
              <th rowspan="2"><strong>Duration (Monthse</strong></th>
              <th rowspan="2"><strong>Start Date</strong></th>
              <th rowspan="2"><strong>End Date</strong></th>
              <th style="background-color:#9e6452" colspan="12"><strong>First Year</strong></th>
              <th style="background-color:#529e7d" colspan="12"><strong>Second Year</strong></th>
              <th colspan="2" rowspan="2"><strong>Status</strong></th>
              <th colspan="2" rowspan="2"><strong>Progress</strong></th>
            </tr>
            <tr>
              <th><strong>J</strong></th>
              <th><strong>F</strong></th>
              <th><strong>M</strong></th>
              <th><strong>A</strong></th>
              <th><strong>M</strong></th>
              <th><strong>J </strong></th>
              <th><strong>J</strong></th>
              <th><strong>A</strong></th>
              <th><strong>S</strong></th>
              <th><strong>O</strong></th>
              <th><strong>N</strong></th>
              <th><strong>D</strong></th>

              <th ><strong>J</strong></th>
              <th><strong>F</strong></th>
              <th><strong>M</strong></th>
              <th><strong>A</strong></th>
              <th><strong>M</strong></th>
              <th><strong>J </strong></th>
              <th><strong>J</strong></th>
              <th><strong>A</strong></th>
              <th><strong>S</strong></th>
              <th><strong>O</strong></th>
              <th><strong>N</strong></th>
              <th><strong>D</strong></th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="item in reportData" :key="item.name">
              <td>{{ item.name }}</td>
              <td>
                {{ item.duration }}
              </td>

              <td >{{ item.startDate }}</td>
              <td>{{ item.endDate }}</td>

              <td :class="getClass(`${item.class1[0]}`)"></td>
              <td :class="getClass(`${item.class1[1]}`)"></td>
              <td :class="getClass(`${item.class1[2]}`)"></td>
              <td :class="getClass(`${item.class1[3]}`)"></td>

              <td :class="getClass(`${item.class1[4]}`)"></td>
              <td :class="getClass(`${item.class1[5]}`)"></td>
              <td :class="getClass(`${item.class1[6]}`)"></td>
              <td :class="getClass(`${item.class1[7]}`)"></td>

              <td :class="getClass(`${item.class1[8]}`)"></td>
              <td :class="getClass(`${item.class1[9]}`)"></td>
              <td :class="getClass(`${item.class1[10]}`)"></td>
              <td :class="getClass(`${item.class1[11]}`)"></td>

              <td  :class="getClass(`${item.class2[0]}`)"></td>
              <td  :class="getClass(`${item.class2[1]}`)"></td>
              <td  :class="getClass(`${item.class2[2]}`)"></td>
              <td  :class="getClass(`${item.class2[3]}`)"></td>

              <td  :class="getClass(`${item.class2[4]}`)"></td>
              <td  :class="getClass(`${item.class2[5]}`)"></td>
              <td  :class="getClass(`${item.class2[6]}`)"></td>
              <td  :class="getClass(`${item.class2[7]}`)"></td>

              <td  :class="getClass(`${item.class2[8]}`)"></td>
              <td  :class="getClass(`${item.class2[9]}`)"></td>
              <td  :class="getClass(`${item.class2[10]}`)"></td>
              <td  :class="getClass(`${item.class2[11]}`)"></td>


              <td colspan="2">
                <template>
                  <v-icon :color="item.status">mdi-flag</v-icon>
                </template>
              </td>
              <td>
                <span>
                  <v-progress-linear
                  
                    :color="
                      item.progress> 60
                        ? 'green'
                        : item.progress < 45
                        ? 'orange'
                        : 'blue'
                    "
                    v-model="item.progress"
                    :buffer-value="100"
                    height="20"
                  >
                    <template v-slot:default="{ value }">
                      <strong>{{ Math.ceil(value) }}%</strong>
                    </template>
                  </v-progress-linear>
                </span>
              </td>
            </tr>
          </tbody>
        </template>
      </table>
    </v-card>
  </v-layout>
</template>


<script>
import { use } from "echarts/core";
import { CanvasRenderer } from "echarts/renderers";
import { PieChart } from "echarts/charts";
import {
  TitleComponent,
  TooltipComponent,
  LegendComponent,
} from "echarts/components";
import VChart, { THEME_KEY } from "vue-echarts";
import ProjectGauge from "../digitaltp_components/project_details/ProjectGauge.vue";
import PageTitle from "../../digitaltp_shared/page-title.vue";

use([
  CanvasRenderer,
  PieChart,
  TitleComponent,
  TooltipComponent,
  LegendComponent,
]);

export default {
  name: "ReportPerComponent",
  components: {
    VChart,
    ProjectGauge,
    PageTitle,
  },
  provide: {
    [THEME_KEY]: "white",
  },
  data() {
    return {
      reportData: [
        {
          name: "Digital Ecosystem",
          duration: 5,
          startDate: "January 4, 2022",
          endDate: "December 4, 2023",
          progress: 34,
          class1:['red','red','red','red','red','red','red','red','red','red','red','red'],
          class2:['red','red','red','red','red','white','white','white','white','white','white','white'],
          status:'primary'
        },
        {
          name: "Digital Platform & Service",
          duration: 6,
          startDate: "January 4, 2022",
          endDate: "December 4, 2025",
          progress: 69,
          class1:['white','white','white','white','red','red','red','red','red','red','red','red'],
          class2:['red','red','red','red','red','red','white','white','white','white','white','white'],
          status:'primary'
        },
        {
          name: "Digital Connectivity",
          duration: 2,
          startDate: "January 4, 2022",
          endDate: "December 4, 2023",
          progress: 49,
          class1:['white','white','white','white','red','red','red','red','red','red','red','red'],
          class2:['white','white','white','white','red','red','red','red','red','red','red','red'],
          status:'primary'
        },
        {
          name: "Digital Ecosystem",
          duration: 5,
          startDate: "January 4, 2022",
          endDate: "December 4, 2023",
          progress: 10,
          class1:['white','white','white','white','white','white','white','red','red','red','red','red'],
          class2:['white','white','white','white','white','white','white','red','red','red','red','red'],
          status:'primary'
        },
        {
          name: "Digital Platform & Service",
          duration: 6,
          startDate: "January 4, 2022",
          endDate: "December 4, 2025",
          progress: 69,
          class1:['white','white','white','white','red','red','red','red','red','red','red','red'],
          class2:['red','red','red','red','red','red','red','red','red','red','red','red'],
          status:'orange'
        },
        {
          name: "Digital Connectivity",
          duration: 2,
          startDate: "January 4, 2022",
          endDate: "December 4, 2023",
          progress: 49,
          class1:['white','white','white','white','white','white','white','white','white','white','white','white'],
          class2:['red','red','red','red','red','red','red','white','white','white','white','white'],
          status:'orange'
        },
        {
          name: "Digital Ecosystem",
          duration: 5,
          startDate: "January 4, 2022",
          endDate: "December 4, 2023",
          progress: 34,
          class1:['white','white','white','white','white','white','white','white','white','red','red','red'],
          class2:['white','white','white','white','white','white','white','white','white','white','white','white'],
          status:'success'
        },
        {
          name: "Digital Platform & Service",
          duration: 6,
          startDate: "January 4, 2022",
          endDate: "December 4, 2025",
          progress: 69,
          class1:['white','white','white','white','white','white','white','white','white','red','red','red'],
          class2:['red','red','red','red','red','red','red','red','red','red','red','red'],
          status:'pink'
        },
        {
          name: "Digital Connectivity",
          duration: 2,
          startDate: "January 4, 2022",
          endDate: "December 4, 2023",
          progress: 49,
          class1:['white','white','white','white','white','white','white','white','white','white','white','red'],
          class2:['red','red','red','red','red','red','red','red','red','red','red','red'],
          status:'success'
        },
      ],
      option: {
        title: {
          text: "Sub-Activity Vs Percentage Completion",
        },
        tooltip: {
          trigger: "axis",
        },
        // legend: {
        //   bottom: "left",
        //   data: ["This Month", "Last Month"],
        // },
        toolbox: {
          show: true,
          feature: {
            dataView: { show: true, readOnly: false },
            magicType: { show: true, type: ["line", "bar"] },
            restore: { show: true },
            saveAsImage: { show: true },
          },
        },
        calculable: true,
        xAxis: [
          {
            type: "category",
            data: ['Digital Ecosystem', 'Digital Connectivity', 'Digital Platform & Services', 'e-Commerce', 'e-Trainings', 'Digital Platform & Services','Capacity Building'],
          },
        ],
        yAxis: [
          {
            type: "value",
          },
        ],
        series: [
          {
            name: "Percentage Completion (%)",
            type: "bar",
            data: [34, 69, 49,80,14,50,70],
            markPoint: {
              data: [
                { type: "max", name: "Max" },
                { type: "min", name: "Min" },
              ],
            },
            markLine: {
              data: [{ type: "average", name: "Avg" }],
            },
          },
        ],
      },
    };
  },

  methods: {
    getClass(a) {
      if (a=="white") {
        this.class="first"
        return this.class
      }
      if (a=="red") {
        this.class = "second"
        return this.class
      }
      else {
        this.class = "third"
        return this.class
      }
    }
}
};
</script>

<style scoped>
.chart {
  height: 400px;
}

table {
  border-collapse: collapse;
  width: 100%;
  padding: 10px;
}


th {
  text-align: center;
  padding: 5px;
  border-top: none;
  border: 0.2px solid  var(--dtp-font-dark);
}
td {
  text-align: left;
  padding: 5px;
  border-top: none;
  border: 1px solid var(--dtp-font-dark);
}

  .first{
      background-color: rgb(255, 255, 255)
    }
    .second {
       background-color: rgb(8, 82, 131)
    }
    .third {
       background-color: green
    }
</style>



